  p span{
    margin: 5px;
  }
  
  p span:first-child{
    margin-right: 0;
  }

.user_msg{
    text-align: right;
    margin-left: 30%;
    display: flex;
    flex-direction: row-reverse;
  }
  
.hide {
    visibility: hidden;
    display: none;
  }
  
  form{
    text-align: center;
    position: sticky;
    bottom: 0;
  }
  
  input{
    width: 100%;
    height: 40px;
    border: none;
    padding: 10px;
    font-size: 1.2rem;
  }
  
  input:focus{
    outline: none;
  }