@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.logo-name {
  font-family: "nasalization", sans-serif;
}

.logo-name span {
  font-family: "nasalization", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #7a7f9d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #21295c;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #21295c;
}

/* ===========================================
   RAG WIZARD STYLES
   =========================================== */

/* Dialog styling */
.MuiDialog-paper {
  background-color: #1e2733 !important;
  color: #e0e0e0 !important;
  border-radius: 8px !important;
  overflow: hidden !important;
  max-width: 1200px !important;
}

/* Dialog header */
.MuiDialogTitle-root {
  background-color: #2c3846 !important;
  border-bottom: 1px solid #3a4655 !important;
  padding: 16px 24px !important;
}

.MuiDialogTitle-root h6 {
  font-weight: 600 !important;
  font-size: 1.25rem !important;
  color: #fff !important;
}

/* Dialog content */
.MuiDialogContent-root {
  background-color: #1e2733 !important; /* Darker background for better contrast */
  padding: 0 !important;
}

/* Step indicators */
.MuiStepper-root {
  background-color: #2c3846 !important;
  padding: 20px 24px !important;
  border-bottom: 1px solid #3a4655 !important;
}

.MuiStepLabel-label {
  color: #9aa5b5 !important;
  font-size: 0.875rem !important;
}

.MuiStepLabel-label.Mui-active {
  color: #0b8ff9 !important;
  font-weight: 600 !important;
}

.MuiStepLabel-label.Mui-completed {
  color: #60d394 !important;
}

.MuiStepIcon-root {
  color: #3a4655 !important;
}

.MuiStepIcon-root.Mui-active {
  color: #0b8ff9 !important;
}

.MuiStepIcon-root.Mui-completed {
  color: #60d394 !important;
}

.MuiStepConnector-line {
  border-color: #3a4655 !important;
}

/* Basic Information step */
.wizard-step-content {
  padding: 30px;
}

.wizard-section-title {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  color: #fff !important;
  margin-bottom: 24px !important;
}

/* Form fields */
.wizard-field {
  margin-bottom: 24px;
}

.wizard-field-label {
  display: block;
  color: #e0e0e0;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 8px;
}

.wizard-input,
.wizard-textarea,
.wizard-select {
  width: 100%;
  background-color: #2c3846;
  border: 1px solid #3a4655;
  color: #e0e0e0;
  padding: 14px 16px;
  border-radius: 6px;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
}

.wizard-input:focus,
.wizard-textarea:focus,
.wizard-select:focus {
  outline: none;
  border-color: #0b8ff9;
  box-shadow: 0 0 0 2px rgba(11, 143, 249, 0.2);
}

.wizard-input::placeholder,
.wizard-textarea::placeholder {
  color: #7a7f9d;
}

.wizard-helper-text {
  color: #9aa5b5;
  font-size: 0.85rem;
  margin-top: 6px;
}

/* Tags */
.wizard-tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
}

.wizard-tag {
  background-color: #3a4655;
  border-radius: 16px;
  padding: 6px 14px;
  color: #e0e0e0;
  font-size: 0.85rem;
}

/* Dialog actions */
.MuiDialogActions-root {
  background-color: #2c3846 !important;
  padding: 16px 24px !important;
  border-top: 1px solid #3a4655 !important;
}

/* Navigation buttons */
.wizard-back-button {
  background-color: transparent !important;
  border: 1px solid #7a7f9d !important;
  color: #c5cdd8 !important;
  padding: 10px 20px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  font-size: 0.95rem !important;
  text-transform: none !important;
}

.wizard-back-button:hover {
  background-color: rgba(122, 127, 157, 0.1) !important;
}

.wizard-next-button {
  background-color: #0b8ff9 !important;
  color: #fff !important;
  padding: 10px 20px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  font-size: 0.95rem !important;
  text-transform: none !important;
}

.wizard-next-button:hover {
  background-color: #0a7ad6 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
}

/* Radio options - IMPROVED BUT SMALLER */
.wizard-radio-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
}

.wizard-radio-option {
  display: flex;
  align-items: flex-start;
  padding: 14px;
  background-color: #2c3846;
  border-radius: 6px;
  border: 1px solid #3a4655;
  cursor: pointer;
  transition: all 0.2s ease;
}

.wizard-radio-option:hover {
  background-color: #323e4d;
}

.wizard-radio-option.selected {
  border-color: #0b8ff9;
  background-color: rgba(11, 143, 249, 0.15);
  box-shadow: 0 0 6px rgba(11, 143, 249, 0.25);
}

.wizard-radio-button {
  margin-right: 12px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #5d6b7e;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.wizard-radio-option.selected .wizard-radio-button {
  border-color: #0b8ff9;
  background-color: rgba(11, 143, 249, 0.1);
}

.wizard-radio-button::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #0b8ff9;
  display: none;
}

.wizard-radio-option.selected .wizard-radio-button::after {
  display: block;
}

.wizard-radio-content {
  flex: 1;
}

.wizard-radio-label {
  font-weight: 500;
  color: #fff;
  margin-bottom: 4px;
  font-size: 0.95rem;
}

.wizard-radio-description {
  font-size: 0.85rem;
  color: #c5cdd8;
  line-height: 1.4;
}

/* Chunking details - IMPROVED BUT SMALLER */
.wizard-chunking-container {
  margin-left: 24px;
  padding: 14px;
  border-left: 2px solid #0b8ff9;
  margin-bottom: 20px;
  background-color: #2c3846;
  border-radius: 0 6px 6px 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

/* Chunking sliders - IMPROVED BUT SMALLER */
.wizard-slider-container {
  margin-bottom: 18px;
  padding: 10px;
  background-color: #384558; /* Better contrast background */
  border-radius: 6px;
  border: 1px solid #4a5a70;
}

.wizard-slider-label {
  display: block;
  color: #ffffff;
  font-weight: 500;
  font-size: 0.95rem;
  margin-bottom: 6px;
}

.wizard-slider-value {
  color: #0b8ff9;
  font-weight: 600;
  font-size: 0.95rem;
  margin-bottom: 8px;
  display: inline-block;
  background-color: rgba(11, 143, 249, 0.15);
  padding: 3px 8px;
  border-radius: 4px;
  min-width: 45px;
  text-align: center;
}

.wizard-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px; /* Thinner but still visible */
  border-radius: 4px;
  background: #3a4655;
  outline: none;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
  margin-bottom: 6px;
}

.wizard-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px; /* More moderate size */
  height: 18px;
  border-radius: 50%;
  background: #0b8ff9;
  cursor: pointer;
  border: 2px solid #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.wizard-slider::-moz-range-thumb {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #0b8ff9;
  cursor: pointer;
  border: 2px solid #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

/* Add track fill styling */
.wizard-slider-track-fill {
  position: absolute;
  height: 8px;
  background-color: rgba(11, 143, 249, 0.5);
  border-radius: 4px 0 0 4px;
  pointer-events: none;
}

.wizard-slider-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
}

.wizard-slider-label-text {
  font-size: 0.85rem;
  color: #e0e0e0;
  font-weight: 400;
}

.wizard-slider-description {
  font-size: 0.85rem;
  color: #c5cdd8;
  margin-top: 8px;
  line-height: 1.4;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 6px;
  border-radius: 4px;
}

/* Emphasize token values */
.token-value-display {
  font-size: 1.1rem;
  color: #ffffff;
  margin-bottom: 4px;
}

.token-value {
  color: #0b8ff9;
  font-weight: 700;
}

/* Improved checkbox options - SMALLER VERSION */
.wizard-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

.wizard-checkbox-option {
  display: flex;
  align-items: center;
  padding: 10px 12px;
  background-color: #2c3846;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #3a4655;
}

.wizard-checkbox-option.selected {
  background-color: rgba(11, 143, 249, 0.15);
  border-color: #0b8ff9;
}

.wizard-checkbox-button {
  margin-right: 12px;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 2px solid #5d6b7e;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.wizard-checkbox-option.selected .wizard-checkbox-button {
  border-color: #0b8ff9;
  background-color: #0b8ff9;
}

.wizard-checkbox-button::after {
  content: '';
  width: 6px;
  height: 10px;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  transform: rotate(45deg) translate(-1px, -1px);
  display: none;
}

.wizard-checkbox-option.selected .wizard-checkbox-button::after {
  display: block;
}

.wizard-checkbox-label {
  color: #fff;
  font-weight: 400;
  font-size: 0.9rem;
}

/* Make document preparation section more readable */
.document-preparation {
  padding: 24px;
  background-color: rgba(44, 56, 70, 0.5);
  border-radius: 8px;
  margin-bottom: 24px;
}

/* Token displays */
.token-display {
  display: inline-block;
  padding: 5px 10px;
  background-color: rgba(11, 143, 249, 0.2);
  color: #ffffff;
  font-weight: 600;
  border-radius: 4px;
  margin-bottom: 4px;
  min-width: 80px;
  text-align: center;
}